const scrollStep = 200;

previous = () => {
    const valeursDiv = getValeursDiv();
    const newVal = valeursDiv.scrollLeft - getScrollStep();
    valeursDiv.scrollLeft = newVal;

    if (newVal <= 0) {
        document.getElementById('previousValeurs').disabled = true;
    }
    document.getElementById('nextValeurs').disabled = false;
}

next = () => {
    const valeursDiv = getValeursDiv();
    const newVal = valeursDiv.scrollLeft + getScrollStep();
    valeursDiv.scrollLeft = newVal;

    if (newVal >= valeursDiv.scrollLeftMax) {
        document.getElementById('nextValeurs').disabled = true;
    }
    document.getElementById('previousValeurs').disabled = false;
}

setCentered = () => {
    const valeursDiv = getValeursDiv();

    if (screen.width > 750 && screen.width <= 900) {
        valeursDiv.scrollLeft = screen.width;
    } else if (screen.width <= 750) {
        valeursDiv.scrollLeft = screen.width * 2;
    } else {
        const unseenScrollWidth = valeursDiv.scrollWidth - valeursDiv.offsetWidth;
        valeursDiv.scrollLeft = unseenScrollWidth / 2;
    }
}

getValeursDiv = () => {
    return document.getElementsByClassName('qui_nous__nos_valeurs')[0];
}

(function () {
    setCentered();
})();

getScrollStep = () => {
    if (screen.width <= 900) {
        return screen.width;
    } else {
        return scrollStep;
    }
}